/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "highlight.js/styles/googlecode.css";
@import "perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "socicon/css/socicon.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

//leaflet
@import "../node_modules/leaflet/dist/leaflet.css";

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}
/* CSS global - styles.scss */

/* Assurez-vous que l'arrière-plan est bien flouté */
body.modal-blur::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px) !important; /* Applique un flou */
  background: rgba(0, 0, 0, 0.5) !important; /* Fond semi-transparent gris */
  z-index: 2000 !important; /* Juste en dessous du modal */
}
body.aside-menu::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  backdrop-filter: blur(8px) !important; /* Applique un flou */
  background: rgba(0, 0, 0, 0.5) !important; /* Fond semi-transparent gris */
  z-index: 2000 !important; /* Juste en dessous du modal */
}

/* Modal doit être bien au-dessus de l'arrière-plan flouté */
.modal {
  z-index: 2050 !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #3699ff !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}
.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 0px solid #adadad !important;
  width: 100%;
  padding: 0px 0px !important;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}
element.style {
  padding-left: 2px;
  color: #8c746a !important;
}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #8c746a !important;
  margin-right: 4px;
  background: #8c746a !important;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  max-width: 100% !important;
}
.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  -webkit-animation: 0.2s ease-in borderscale;
  animation: 0.2s ease-in borderscale;
  background: #8c746a !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div:before {
  box-sizing: content-box;
  content: "";
  color: #8c746a !important;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #8c746a !important;
  text-align: center;
  transition: 0.4s;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-bottom: -3%;
  background-color: #8c746a;
}
.carousel-control-prev-icon {
  //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  margin-right: 58% !important;
  background-color: #8c746a;
}
.carousel-control-next-icon {
  //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  margin-right: -45% !important;
  background-color: #8c746a;
}

@import "/node_modules/ngx-bar-rating/themes/br-default-theme.scss";
@import "/node_modules/ngx-bar-rating/themes/br-square-theme";
@import "/node_modules/ngx-bar-rating/themes/br-movie-theme";
.br-default {
  --br-font-size: 55px !important;
}
.br-square {
  --br-active-color: #7f9dff;
}

.br-square .br-unit-inner {
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "/src/assets/styles/custom.scss";
.sun-editor .se-resizing-bar {
  display: none !important;
}
@import "node_modules/suneditor/dist/css/suneditor.min.css";
.my-custom-tooltip {
  max-width: unset !important;
}
/* organigrame-entreprise-modal.component.scss */
.oc-name {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.oc-designation {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.oc-border {
  border-color: #9e9e9e;
}

.oc-box {
  color: white;
  width: 10em;
}
//oc image

// Custom cssClass from Employee object
.oc-superSenior {
  background-color: bisque !important;
  width: max-content !important;
}

.oc-senior {
  background-color: darkseagreen !important;
  background-position: center;
  background-size: cover;
  width: max-content !important;
}

.oc-me {
  background-color: darkseagreen !important;
  width: max-content !important;
}

.oc-junior {
  background-color: darkseagreen !important;
  background-position: center;
  background-size: cover;
  width: max-content !important;
}

.oc-superJunior {
  background-color: darkseagreen !important;
}
.oc-image {
  background-position: center !important;
  background-size: cover !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
