@use "@angular/material" as mat;

@include mat.core();

$primary: #d8aa72;
$success: #1bc5bd;
$danger: #f64e60;

$dark-primary-text: #fcf6e9;
$light-primary-text: #8c746a;

// Define the palettes for your theme using your existing color variables.
// Ensure that your color variables ($primary, $success, $danger, etc.) are defined above this section.
$mat-primary: (
  50: lighten($primary, 8%),
  100: $primary,
  200: darken($primary, 8%),
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
  ),
);

$mat-accent: (
  50: lighten($success, 8%),
  100: $success,
  200: darken($success, 8%),
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
  ),
);

$mat-warn: (
  50: lighten($danger, 8%),
  100: $danger,
  200: darken($danger, 8%),
  contrast: (
    50: $dark-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
  ),
);

// Use the define-palette function to create palettes.
$angular-app-primary: mat.define-palette($mat-primary, 100, 50, 200);
$angular-app-accent: mat.define-palette($mat-accent, 100, 50, 200);
$angular-app-warn: mat.define-palette($mat-warn, 100, 50, 200);

// Create the theme object (a Sass map containing all of the palettes).
$angular-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-app-primary,
      accent: $angular-app-accent,
      warn: $angular-app-warn,
    ),
    // Include additional theme configurations like typography here if needed
    // typography: mat.define-typography-config(),
    // density: 0,,,,,,,,,,,,,,,,,
  )
);

// Include Angular Material's core and theme styles
@include mat.core();
@include mat.all-component-themes($angular-app-theme);

.mdc-button__label {
  color: #8c746a !important;
}
.mat-mdc-raised-button {
  color: #8c746a !important;
}
