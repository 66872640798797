//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors

// Override primary color variants

$primary: #d8aa72; // Bootstrap variable

$primary-hover: #b98068; // Custom variable

$primary-light: #fcf6e9; // Custom variable

$primary-inverse: #fcf6e9; // Custom variable

$secondary: #c59681;

$pink: #ff8a7f;
